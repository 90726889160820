import React, { useEffect, useMemo, useRef, useState } from "react";
import { AppLayout } from "components";
import ActionItems from "./ActionItems";
// import { ReactComponent as DotsIcon } from "assets/svg/dots.svg";
// import { ReactComponent as BackIcon } from "assets/svg/Back.svg";
import {
  useNavigate,
  useParams,
  Routes,
  Route,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import ProjectPlanDetails from "./ProjectPlanDetails";
import Resources from "./Resources";
// import { ReactComponent as InfoWhite } from "assets/svg/infoWhite.svg";
// import SortByGroup from 'scenes/ProjectPlanner/SortByGroup'
import {
  archivePlan,
  createNotes,
  createReminder,
  createCustomLabelAndUnits,
  createRFile,
  createTool,
  // deleteGoal,
  deleteNotes,
  deleteRFile,
  deleteTool,
  getPlanByID,
  // getPlans,
  getTools,
  getAllRequest,
  removePlan,
  updateGoal,
  updatePlan,
  updateResources,
  upateNotes,
  getSharedProjectByID,
} from "modules/actions/PlanActions";
import { useDispatch, useSelector } from "react-redux";
// import { COLORS } from "constants/colors";
import DeleteModal from "./Modal/DeleteModal";
import ReminderModal from "./Modal/ReminderModal";
import CreateToolModal from "./Modal/CreateToolModal";
import NoteModal from "./Modal/NoteModal";
// import SortByGroup from "scenes/ProjectPlanner/SortByGroup";
import RenamePlanModal from "./Modal/RenamePlanModal";
// import { DownOutlined } from "@ant-design/icons";
import moment from "moment";
import { getProjectOwners } from "modules/actions/UserActions";
import { toast } from "react-toastify";

// import c from "classnames";
// import styles from "./styles.module.scss";
import CustomModal from "./Modal/CustomModal";
// import { CATEGORY_INFO, GOAL_INFO, PLAN_INFO } from "constants/index";
import { onlySpaces, useToken } from "utils/utility";
import { AppLoading } from "components/AppLoading";

import ViewOnly from "./ViewOnly";
import PlanNewHeader from "./PlanNewHeader";
import {
  bulkUpdatePlanActionCategory,
  clearSelectedGoalList,
  getGaolList,
  getGoalListById,
} from "../../modules/actions/PlanActions";
import { getCustomizeColumn } from "../../modules/actions/PlanActions";
import { websocketConnection } from "../../utils/websocket";
import Insights from "./Insights";

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const PlanDetails = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { token } = useToken();
  const [isNewUser, setIsNewUser] = useState(false);
  const [isWSLoading, setIsWSLoading] = useState(false);
  const [webSocketResponse, setWebSocketResponse] = useState(null);
  const [isListView, setIsListView] = useState(true);

  const { users } = useSelector((state) => state.user.projectOwners);

  const [isExpandAllNotes, setIsExpandAllNotes] = useState(false);

  const { loading: loadingNoteDelete, loadingNoteUpdate } = useSelector(
    (state) => state.plan.plan
  );

  const {
    loading,
    planDetails,
    loadingArchive,
    loadingDelete,
    loadingUpdatePlan,
    tools,
  } = useSelector((state) => state.plan.plan);

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const goalLabelRef = useRef(null);
  const isShared = pathname.includes("shared-project");
  const clone_decision = query.get("clone");
  const isShortView = query.get("short") === "true";

  useEffect(() => {
    const isAIGenerate = localStorage.getItem("isAIGenerate");
    const isAIGeneratedPlan = searchParams.get("isAI");

    if (
      isAIGeneratedPlan &&
      typeof isAIGeneratedPlan === "string" &&
      Boolean(isAIGeneratedPlan) === true &&
      isAIGenerate === "true"
    ) {
      if (planDetails) {
        setIsWSLoading(true);

        const sendMessage = () => {
          // Check if WebSocket is connected
          if (websocketConnection.isConnected()) {
            // Send message if connected
            websocketConnection.send(
              JSON.stringify({
                endpoint: "generate_category_action",
                model_id: id,
                title: planDetails.name,
              })
            );
            // Remove "isAI" from the search params
            setSearchParams((params) => {
              localStorage.removeItem("isAIGenerate");
              params.delete("isAI");
              return params;
            });
          } else {
            websocketConnection.reconnect();
            // Retry until connected
            const intervalId = setInterval(() => {
              if (websocketConnection.isConnected()) {
                websocketConnection.send(
                  JSON.stringify({
                    endpoint: "generate_category_action",
                    model_id: id,
                    title: planDetails.name,
                  })
                );
                clearInterval(intervalId); // Stop retrying after message is sent
                setSearchParams((params) => {
                  localStorage.removeItem("isAIGenerate");
                  params.delete("isAI");
                  return params;
                });
              }
            }, 1000); // Retry every second
          }
        };

        sendMessage();
      }
    }
  }, [searchParams, id, planDetails]);

  useEffect(() => {
    websocketConnection?.setMessageCallback(handleMessage);

    return () => {
      setWebSocketResponse(null);
    };
  }, []);

  const handleMessage = async (message) => {
    // Handle the received message
    let res = JSON?.parse(message);
    if (res?.ping || res?.pong) return;
    setIsWSLoading(false);
    setWebSocketResponse(res?.message);
  };

  useEffect(() => {
    if (webSocketResponse) {
      dispatch(
        bulkUpdatePlanActionCategory(webSocketResponse?.category, () => {
          handleGetPlan(isShared);
          setWebSocketResponse(null);
        })
      );
    }
  }, [webSocketResponse]);

  useEffect(() => {
    goalLabelRef.current = planDetails?.goal?.label;
  }, [planDetails]);

  const [state, setState] = useState({
    activeKey: [],
    allAreas: [],
    selectedStatus: [],
    selectedLocations: [],
    openCustom: false,
    visible: false,
    isTableView: false,
    deleteModal: false,
    deleteArchiveModal: false,
    reminderModal: false,
    createToolModal: false,
    renamePlanModal: false,
    peoplePop: false,
    requestPop: false,
    toolPop: false,
    repeat: "",
    repeat_day: "",
    reminder_time: "",
    toolName: "",
    planName: "",
    selectedUsers: [],
    selectedTools: [],
    selectedWeight: "lbs",
    notetype: "",
    labelName: "",
    labelValue: "",
    selectedUnitValue: [],
    noteID: "",
    updateModal: false,
    short: false,
  });

  const {
    selectedTools,
    toolPop,
    deleteModal,
    deleteArchiveModal,
    reminderModal,
    repeat,
    reminder_time,
    repeat_day,
    createToolModal,
    renamePlanModal,
    toolName,
    selectedUsers,
    peoplePop,
    requestPop,
    noteModal,
    updateModal,
    notetype,
    context,
    deleteNoteModal,
    deleteID,
    openCustom,
    labelName,
    labelValue,
    selectedUnitValue,
    noteID,
  } = state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  useEffect(() => {
    if (isNewUser) {
      setIsNewUser(false);
      return;
    }
    const filterUsers = selectedUsers
      .map((ele, index) => {
        return planDetails?.resource?.people?.filter((element, ind) => {
          return element.email === ele.email;
        });
      })
      .flat();
    handleChange("selectedUsers", filterUsers);
  }, [peoplePop]);

  const handleGetPlan = (isShared) => {
    if (isShared) {
      dispatch(
        getSharedProjectByID(id, (callback) => {
          if (callback === 404) {
            navigate("/project-planner", { replace: true });
          }
        })
      );
    } else {
      dispatch(
        getPlanByID(id, (callback) => {
          if (callback === 404) {
            navigate(`/project-planner/request-access?id=${id}`, {
              replace: true,
            });
          }
        })
      );
    }

    if (!isShared) {
      dispatch(getProjectOwners(""));

      setTimeout(() => {
        const qs = `?project_plan=${id}`;
        dispatch(getProjectOwners(qs, true));
      }, 1000);
    }
  };
  const handleUpdatePlan = (payload) => {
    const { goalName, ...rest } = payload;

    const payloadData = {
      name: goalName === "null" ? null : goalName,
    };

    dispatch(updatePlan(id, rest, closeModal, true));
    if (goalName) {
      dispatch(updateGoal(planDetails?.goal?.id, payloadData, id));
    }
  };

  const handleUpdate = (key, value) => {
    const payload = {
      [key]: value,
    };
    handleUpdatePlan(payload);
  };

  const handleUpdateGoal = (key, value, label) => {
    if (
      (key === "start" || key === "current" || key === "target") &&
      !goalLabelRef.current
    ) {
      handleChange(key, 0);
      toast("Please select unit first", { type: "error" });
      return;
    }

    const payload = {
      [key]: value,
    };

    if (label) {
      payload["label"] = label;
    }

    dispatch(updateGoal(planDetails?.goal?.id, payload, id, key));
  };

  const handleRemoveNote = (payload) => {
    dispatch(deleteNotes(payload, id, closeModal));
  };

  const handleRemoveNoteModal = (id) => {
    handleChange("deleteNoteModal", true);
    handleChange("deleteID", id);
  };

  const handleEditNoteModal = (id, context) => {
    handleChange("updateModal", true);
    handleChange("noteID", id);
    handleChange("context", context);
  };

  const handleRemoveNotePress = () => {
    handleRemoveNote(deleteID);
  };

  const handleUpdateResources = (payload, message) => {
    dispatch(
      updateResources(
        planDetails?.resource?.id,
        payload,
        id,
        closeModal,
        message
      )
    );
  };

  const handleCreateReminder = () => {
    const payload = {
      project_plan: id,
      type: repeat?.toLowerCase(),
      duration:
        repeat === "Custom" || repeat === "Monthly" || repeat === "Weekly"
          ? repeat_day + moment(reminder_time).format(" hh:mm:ss")
          : moment(reminder_time).format("hh:mm:ss"),
    };
    dispatch(createReminder(payload, id, closeModal));
  };

  const handleCreateCustomUnit = () => {
    const payload = {
      value: labelName,
      name: labelName,
      goal: planDetails?.goal?.id,
    };

    dispatch(
      createCustomLabelAndUnits(payload, selectedUnitValue, id, closeModal)
    );
  };

  const handleAddGoalNote = (context, clearContextState) => {
    const payload = {
      context,
      project_plan: planDetails?.id,
    };
    const closeAll = () => {
      clearContextState();
      closeModal();
    };
    dispatch(createNotes(payload, planDetails?.id, closeAll));
  };

  const handleAddResourceNote = () => {
    const payload = {
      context,
      resource: planDetails?.resource?.id,
    };
    dispatch(createNotes(payload, planDetails?.id, closeModal));
  };

  const goBackDeleteModal = () => {
    navigate("/project-planner");
    handleChange("deleteModal", false);
  };

  const handleRemove = (e) => {
    dispatch(removePlan(parseInt(id), goBackDeleteModal));
  };

  const closeArchive = () => {
    navigate("/");
    closeModal();
  };

  const archiveBack = () => {
    navigate("/project-planner");
  };

  const handleArchive = (e) => {
    const payload = {
      project_plan: parseInt(id),
      is_archived: true,
    };
    dispatch(archivePlan(payload, archiveBack, closeArchive));
  };

  const handleRemoveModal = (e) => {
    handleChange("deleteModal", true);
  };

  const handleArchiveModal = (e) => {
    handleChange("deleteArchiveModal", true);
  };

  const handleAddTool = (payload) => {
    dispatch(createTool(payload, closeModal));
  };

  const handleRemoveTool = (payload) => {
    dispatch(deleteTool(payload, id));
  };

  const handleAddRFile = (payload) => {
    dispatch(createRFile(payload, id));
  };

  const handleRemoveRFile = (payload) => {
    dispatch(deleteRFile(payload, id));
  };

  const handleReminderModal = (e) => {
    handleChange("reminderModal", true);
  };

  const goBack = () => {
    navigate(-1);
  };

  const closeModal = () => {
    handleChange("createToolModal", false);
    handleChange("updateModal", false);
    handleChange("renamePlanModal", false);
    handleChange("noteModal", false);
    handleChange("noteID", "");
    handleChange("reminderModal", false);
    handleChange("toolPop", false);
    handleChange("toolName", "");
    handleChange("reminder_time", "");
    handleChange("repeat_day", "");
    handleChange("notetype", "");
    handleChange("context", "");
    handleChange("deleteNoteModal", "");
    handleChange("deleteID", "");
    handleChange("selectedUsers", []);
    handleChange("peoplePop", false);
    handleChange("requestPop", false);
    handleChange("selectedUnitValue", []);
    handleChange("openCustom", false);
    handleChange("labelName", "");
    handleChange("labelValue", "");
    handleChange("deleteArchiveModal", false);
  };

  const handleAddNote = (context, clearContextState) => {
    if (onlySpaces(context)) {
      toast("Please enter notes", { type: "error" });
      return;
    }
    if (notetype === "resource") {
      handleAddResourceNote();
    } else if (notetype === "goal") {
      handleAddGoalNote(context, clearContextState);
    }
  };

  const handleUpdateNote = (context, clearContextState) => {
    if (onlySpaces(context)) {
      toast("Please enter notes", { type: "error" });
      return;
    }
    const payload = {
      context,
    };
    const closeAll = () => {
      clearContextState();
      closeModal();
    };
    dispatch(upateNotes(noteID, payload, planDetails?.id, closeAll));
  };

  useEffect(() => {
    if (id /* && token */) {
      handleGetPlan(isShared);

      if (!isShared) {
        dispatch(getTools());
        dispatch(getGaolList());
        dispatch(getAllRequest(id));
        dispatch(getCustomizeColumn(id));
      }
    }
  }, [id, token, isShared]);

  useEffect(() => {
    if (planDetails?.goal_public_id && !isShared) {
      dispatch(getGoalListById(planDetails?.goal_public_id));
    } else {
      dispatch(clearSelectedGoalList());
    }
  }, [planDetails?.goal_public_id]);

  useEffect(() => {
    if (planDetails && parseInt(id) === planDetails?.id && !isShared) {
      handleChange("selectedUsers", planDetails?.resource?.people);
      setState((prevState) => {
        const existingTools = prevState.selectedTools || [];
        const newTools = planDetails?.resource?.tools || [];
        // Combine existing and new tools
        const combinedTools = [...newTools, ...existingTools].filter(
          (tool) => tool && tool.id
        );

        // Remove duplicates based on id
        const uniqueTools = combinedTools.reduce((acc, tool) => {
          if (!acc.some((existingTool) => existingTool.id === tool.id)) {
            acc.push(tool);
          }
          return acc;
        }, []);

        return {
          ...prevState,
          selectedTools: uniqueTools,
        };
      });

      handleChange("planName", planDetails?.name);
      handleChange("name", planDetails?.goal?.name || "");
    }
  }, [planDetails, id]);

  /* const menu = (
    <Menu
      className="removeOption"
      items={[
        {
          key: "1",
          onClick: () => handleChange("renamePlanModal", true),
          label: <span className="text_white upercase">REname Plan</span>,
        },
        {
          type: "divider",
          style: { backgroundColor: "#4C545B" },
          color: "#4C545B",
        },
        {
          key: "2",
          onClick: handleArchiveModal,
          label: <span className="text_white upercase">Archive Plan</span>,
        },
        {
          type: "divider",
          style: { backgroundColor: "#4C545B" },
          color: "#4C545B",
        },
        {
          key: "3",
          onClick: handleRemoveModal,
          label: <span className="text_red upercase">Delete Plan</span>,
        },
      ]}
    />
  ); */

  /* const getPlanNotesLength = () => {
    // const goalNotes = planDetails?.notes?.filter(e => e?.goal)
    return planDetails?.goal?.notes?.length || 0;
  }; */

  /* const handleKeyPress = (event, type) => {
    if (event?.key === "Enter")
      if (type === "start") {
        startRef?.current?.blur();
        handleUpdateGoal("start", start);
      } else if (type === "current") {
        currentRef?.current?.blur();
        handleUpdateGoal("current", current);
      } else {
        targetRef?.current?.blur();
        handleUpdateGoal("target", target);
      }
  }; */

  const appLoading =
    !planDetails ||
    (planDetails?.id !== parseInt(id) && !isShared);

  return (
    <AppLayout>
      <AppLoading loading={appLoading} />

      {appLoading ? null : (
        <>
          {isShared && <ViewOnly id={id} isCloning={clone_decision} />}

          <PlanNewHeader
            isShared={isShared}
            handleChange={handleChange}
            handleUpdate={handleUpdate}
            handleUpdateGoal={handleUpdateGoal}
            handleArchiveModal={handleArchiveModal}
            handleRemoveModal={handleRemoveModal}
            isExpandAllNotes={isExpandAllNotes}
            setIsExpandAllNotes={setIsExpandAllNotes}
            isListView={isListView}
            setIsListView={setIsListView}
          />

          <div
            style={{
              padding: "20px",
            }}
          >
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <ActionItems
                    planDetails={planDetails}
                    isShort={isShortView}
                    isShared={isShared}
                    isExpandAllNotes={isExpandAllNotes}
                    isAIGeneratedPlanLoading={isWSLoading}
                    isListView={isListView}
                  />
                }
              />

              <Route
                exact
                path="/details"
                element={
                  <ProjectPlanDetails
                    planDetails={planDetails}
                    deleteNoteModal={deleteNoteModal}
                    loadingNoteDelete={loadingNoteUpdate}
                    handleEditNoteModal={handleEditNoteModal}
                    handleRemoveNoteModal={handleRemoveNoteModal}
                    handleUpdatePlan={handleUpdatePlan}
                    handleUpdateGoal={handleUpdateGoal}
                    handleRemoveNotePress={handleRemoveNotePress}
                    handleRemoveNote={handleRemoveNote}
                    handleChangeMain={handleChange}
                  />
                }
              />

              <Route
                exact
                path="/resources"
                element={
                  <Resources
                    planDetails={planDetails}
                    users={users}
                    handleRemoveTool={handleRemoveTool}
                    handleAddRFile={handleAddRFile}
                    handleRemoveRFile={handleRemoveRFile}
                    handleChange={handleChange}
                    handleSubmit={handleUpdateResources}
                    loading={loading}
                    peoplePop={peoplePop}
                    requestPop={requestPop}
                    tools={tools}
                    selectedUsers={selectedUsers}
                    toolPop={toolPop}
                    toolName={toolName}
                    handleAddTool={handleAddTool}
                    selectedTools={selectedTools}
                    isNewUser={isNewUser}
                    setIsNewUser={setIsNewUser}
                  />
                }
              />

              <Route exact path="/insights" element={<Insights />} />
            </Routes>
          </div>
        </>
      )}
      <DeleteModal
        visible={deleteArchiveModal}
        isPlan
        heading={"Send To Archive"}
        onClose={() => handleChange("deleteArchiveModal", false)}
        descriptions={
          <span>
            Are you sure you want to archive <b>"{planDetails?.name}"</b>
            project plan?
          </span>
        }
        planDetails={planDetails}
        handleChange={handleChange}
        handleRemove={handleArchive}
        // handleArchive={handleArchive}
        loading={loadingArchive}
      />
      <DeleteModal
        visible={deleteModal}
        heading={"Delete Plan"}
        onClose={() => handleChange("deleteModal", false)}
        descriptions={
          <span>
            Are you sure you want to delete <b>"{planDetails?.name}"</b> project
            plan?
          </span>
        }
        planDetails={planDetails}
        handleChange={handleChange}
        handleRemove={handleRemove}
        handleArchive={handleArchive}
        loading={loadingDelete}
        loadingArchive={loadingArchive}
      />
      <CreateToolModal
        visible={createToolModal}
        handleChange={handleChange}
        handleSubmit={handleAddTool}
        loading={loading}
        id={id}
        toolName={toolName}
      />
      <NoteModal
        visible={noteModal}
        handleChange={handleChange}
        handleSubmit={handleAddNote}
        loading={loading}
        isEdit={false}
        closeModal={closeModal}
        id={id}
        context={context}
      />
      <NoteModal
        visible={updateModal}
        isEdit={true}
        handleChange={handleChange}
        handleSubmit={handleUpdateNote}
        closeModal={closeModal}
        loading={loadingNoteUpdate}
        id={id}
        context={context}
      />
      <RenamePlanModal
        visible={renamePlanModal}
        planDetails={planDetails}
        handleChange={handleChange}
        handleSubmit={handleUpdatePlan}
        loading={loadingUpdatePlan}
        users={users}
        selectedUsers={selectedUsers}
      />
      <ReminderModal
        visible={reminderModal}
        repeat={repeat}
        repeat_day={repeat_day}
        reminder_time={reminder_time}
        handleChange={handleChange}
        handleCreate={handleCreateReminder}
        loading={loadingDelete}
      />
      <CustomModal
        visible={openCustom}
        labelName={labelName}
        labelValue={labelValue}
        selectedUnitValue={selectedUnitValue}
        handleChange={handleChange}
        handleSubmit={handleCreateCustomUnit}
        loading={loading}
      />
    </AppLayout>
  );
};

export default PlanDetails;
