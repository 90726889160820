import { Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import {
  updatePlanCategory,
  createPlanCategory,
  // @ts-ignore
} from "modules/actions/PlanActions";
import { useDispatch, useSelector } from "react-redux";

const WeightTitle = ({
  data,
  isNewCategory,
  sortedCategories,
  setSortedCategories,
  setEdit,
}: {
  data: any;
  isNewCategory: any;
  sortedCategories: any;
  setSortedCategories: any;
  setEdit: any;
}) => {
  const dispatch = useDispatch();
  const containerRef = useRef<any>(null);
  const [inputValue, setInputValue] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const { planDetails } = useSelector(
    // @ts-ignore
    (state) => state.plan.plan
  );

  const filteredCategories = planDetails?.categories?.find(
    (category: any) => category.id === data.id
  );

  const changeName = () => {
    if (inputValue.trim() === "") {
      if (data?.isNew) {
        const updatedCategories = sortedCategories.filter(
          (category: any) => category.id !== data.id
        );
        setSortedCategories(updatedCategories);
      } else {
        toast("Category name is required", { type: "error" });
        setInputValue(data?.name || "");
      }
      return;
    } else if (inputValue !== data?.name) {
      setIsEdit(false);
      setEdit(false);

      const updatedCategories = sortedCategories.map((category: any) => {
        if (category.id === data.id) {
          return {
            ...category,
            name: inputValue,
          };
        }
        return category;
      });

      setSortedCategories(updatedCategories);

      if (data?.isNew) {
        const payload = {
          name: inputValue,
          start_line: data?.start_line,
          dead_line: data?.dead_line,
          project_plan: data?.project_plan,
        };

        dispatch(createPlanCategory(payload));
      } else {
        const updateData = {
          name: inputValue,
        };

        dispatch(updatePlanCategory(data?.id, updateData));
      }
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      setIsEdit(false);
      setEdit(false);

      changeName();
    } else if (e.key === "Escape") {
      setIsEdit(false);
      setEdit(false);
    }
  };

  const handleBlur = () => {
    changeName();
    setIsEdit(false);
    setEdit(false);
  };

  useEffect(() => {
    setInputValue(data?.name || "");
  }, [data]);

  useEffect(() => {
    if (isNewCategory && !data?.name && data?.isNew) {
      setIsEdit(true);
      setEdit(true);
    }
  }, [isNewCategory]);

  useEffect(() => {
    if (isEdit) {
      containerRef.current.querySelector("input").focus();
    }
  }, [isEdit]);

  return (
    <WeightContainer onClick={() => setIsEdit(true)} ref={containerRef}>
      {isEdit ? (
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <span
          onClick={() => {
            setIsEdit(true);
            setEdit(true);
          }}
        >
          {inputValue}
        </span>
      )}
    </WeightContainer>
  );
};

export default WeightTitle;

const WeightContainer = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #171717;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;
