import React, { useEffect, useState, useRef } from "react";
import { DatePicker } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import moment from "moment";
import DeleteNoteModal from "../Modal/DeleteNoteModal";
import styles from "./styles.module.scss";

import { ReactComponent as EditIcon } from "./edit.svg";
import { ReactComponent as ArrowDownGray } from "assets/svg/arrowDownGray.svg";
import { ReactComponent as InfoSmallIcon } from "assets/svg/infoSmallGray.svg";

import { ReactComponent as DeleteIcon } from "./delele.svg";
import { useDispatch, useSelector } from "react-redux";
import { createNotes } from "../../../modules/actions/PlanActions";
import { toast } from "react-toastify";
import { getPath } from "../../../components/AppSwitcher";
import GoalSearch from "../../../components/GoalSearch";

import InputResize from "../../../components/InputResize";

export default function ProjectPlanDetails({
  planDetails,
  handleUpdatePlan,
  handleChangeMain,
  deleteNoteModal,
  handleRemoveNoteModal,
  handleRemoveNotePress,
  loadingNoteDelete,
  handleUpdateGoal,
  handleEditNoteModal,
}) {
  const goalRef = useRef(null);
  const { gpsURL } = getPath();

  const dispatch = useDispatch();
  const [value, setValue] = useState("");

  const [isGoalSelect, setIsGoalSelect] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [searchGoal, setSearchGoal] = useState("");

  const [state, setState] = useState({
    purpose: planDetails?.purpose || "",
    name: planDetails?.name || "",
    duration: planDetails?.duration || "",
    description: planDetails?.description || "",
    start_line: moment(planDetails?.start_line) || moment(),
    dead_line: moment(planDetails?.dead_line) || moment(),
    objective: planDetails?.objective || "",
    goalName: planDetails?.goal?.name || "",
    idea: planDetails?.idea || "",
    note: planDetails?.note || "N/A",
  });

  const [words, setWords] = useState({
    name: 0,
    objective: 0,
    purpose: 0,
    idea: 0,
  });

  const { loadingNote, allGoalList, selectedGoalList, loadingUpdatePlan } =
    useSelector((state) => state.plan.plan);

  const { user } = useSelector((state) => state.auth);

  const userId =
    user?.data?.external_accounts?.find(
      (account) => account.decision_and_project
    )?.decision_and_project ?? null;

  const onwer = planDetails?.owner == userId;

  const [endDate, setEndDate] = useState();
  const {
    purpose,
    name,
    description,
    objective,
    duration,
    goalName,
    start_line,
    dead_line,
    idea,
  } = state;
  useEffect(() => {
    setEndDate(dead_line);
  }, []);

  const momentDeadLine = moment(dead_line, "YYYY-MM-DD").format("YYYY-MM-DD");
  const momentStartLine = moment(start_line, "YYYY-MM-DD").format("YYYY-MM-DD");

  useEffect(() => {
    if (planDetails) {
      handleChangeState("name", planDetails?.name);
      handleChangeState("objective", planDetails?.objective);
    }
  }, [planDetails]);

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
    if (key === "start_line" || key === "dead_line") {
      handleUpdate(key, moment(value).format("YYYY-MM-DD"));
    } else {
      handleUpdate(key, value);
    }
  };

  const handleChangeState = (key, value) => {
    let input = value;

    if (input?.startsWith(" ")) {
      input = input.trimStart();
    }
    setState((pre) => ({ ...pre, [key]: input }));
  };

  const handleUpdate = (key, value) => {
    const payload = {
      [key]: value,
    };
    // handleUpdatePlan(payload);
  };

  const handleSave = () => {
    if (!name || !start_line || !dead_line) {
      toast.error("Please fill all required fields");
      return;
    }

    if (momentDeadLine && momentDeadLine < momentStartLine) {
      toast.error("End date should be greater than start date");
      return;
    }

    const formateData = {
      start_line: moment(start_line).format("YYYY-MM-DD"),
      dead_line: moment(dead_line).format("YYYY-MM-DD"),
      name,
      objective: objective || "",
      purpose,
      idea,
      description,
      goal_public_id: selectedGoal?.id || "null",
      // goalName: selectedGoal?.id || "null",
    };

    handleUpdatePlan(formateData);
  };

  const disableSaveButton =
    name === planDetails?.name &&
    objective === planDetails?.objective &&
    moment(start_line)?.format("YYYY-MM-DD") === planDetails?.start_line &&
    moment(dead_line)?.format("YYYY-MM-DD") === planDetails?.dead_line &&
    goalName === String(planDetails?.goal?.name || "") &&
    selectedGoal?.id === planDetails?.goal_public_id &&
    idea === planDetails?.idea &&
    purpose === planDetails?.purpose &&
    description === planDetails?.description;

  const handleAddNote = () => {
    if (!value) {
      toast.error("Note is required");
      return;
    }
    const payload = {
      context: value,
      project_plan: planDetails?.id,
    };

    const closeAll = () => {
      setValue("");
    };

    dispatch(createNotes(payload, planDetails?.id, closeAll));
  };

  const handleCancel = () => {
    setState({
      purpose: planDetails?.purpose || "",
      name: planDetails?.name || "",
      duration: planDetails?.duration || "",
      description: planDetails?.description || "",
      start_line: moment(planDetails?.start_line) || moment(),
      dead_line: moment(planDetails?.dead_line) || moment(),
      objective: planDetails?.objective || "",
      goalName: planDetails?.goal?.name || "",
      idea: planDetails?.idea || "",
      note: planDetails?.note || "N/A",
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (goalRef.current && !goalRef.current.contains(event.target)) {
        setIsGoalSelect(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [goalRef]);

  useEffect(() => {
    if (selectedGoalList && planDetails?.goal_public_id) {
      setSelectedGoal({
        id: selectedGoalList?.id,
        goal_name: selectedGoalList?.goal_name,
        type: selectedGoalList?.type,
        color: selectedGoalList?.color,
      });
    }
  }, [selectedGoalList]);

  useEffect(() => {
    if (planDetails) {
      setWords({
        name: planDetails?.name?.length,
        objective: planDetails?.objective?.length,
        purpose: planDetails?.purpose?.length,
        idea: planDetails?.idea?.length,
      });
    }
  }, [planDetails]);

  return (
    <>
      <div className={styles.projectPlanDetails}>
        <div className={styles.projectPlanDetails_form}>
          <div className={styles.projectPlanDetails_header}>
            <h2>Project Plan Details</h2>

            <div className={styles.projectPlanDetails_form_actions}>
              <button
                disabled={loadingUpdatePlan || disableSaveButton}
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                loading={loadingUpdatePlan}
                disabled={loadingUpdatePlan || disableSaveButton}
              >
                {loadingUpdatePlan ? <LoadingOutlined /> : "Save"}
              </button>
            </div>
          </div>

          <div className={styles.form}>
            <div className={styles.form_left}>
              <InputResize
                label="Project Name"
                value={name || ""}
                onChange={(value) => {
                  let input = value;

                  if (input.length > 500) {
                    input = input.slice(0, 500);
                  }

                  setWords((pre) => ({
                    ...pre,
                    name: input.length,
                  }));

                  handleChangeState("name", input);
                }}
                onBlur={() => handleUpdate("name", name)}
                placeholder="Enter your name"
                words={words?.name}
                error={!name}
                errorMessage="Project Name is required"
              />

              <div className={styles.inputGroup}>
                <label className={styles.label}>Goal (Optional)</label>

                <div className={styles.goal_input_wrapper}>
                  <div
                    ref={goalRef}
                    className={styles.goal_input}
                    onClick={() => onwer && setIsGoalSelect(!isGoalSelect)}
                    style={{
                      borderLeft: selectedGoal
                        ? `4px solid ${selectedGoal?.color || "#A3A3A3"}`
                        : "1px solid #e5e5e5",
                    }}
                  >
                    {selectedGoal ? (
                      <div
                        className={styles.goal_input_selected}
                        style={{
                          borderLeftColor: selectedGoal?.color || "#A3A3A3",
                        }}
                      >
                        <h3>{selectedGoal?.goal_name || "No Name"}</h3>
                      </div>
                    ) : (
                      "Select goal"
                    )}

                    <ArrowDownGray />
                  </div>

                  <GoalSearch
                    allGoalList={allGoalList}
                    goalRef={goalRef}
                    onwer={onwer}
                    setSelectedGoal={setSelectedGoal}
                    setGoalName={handleChangeState}
                    setIsGoalModel={setIsGoalSelect}
                    isGoalSelect={isGoalSelect}
                    setIsGoalSelect={setIsGoalSelect}
                    selectedGoal={selectedGoal}
                    isProjectDetail={true}
                  />
                </div>
              </div>

              <InputResize
                label="Objective"
                value={objective || ""}
                onChange={(value) => {
                  let input = value;

                  if (input.length > 500) {
                    input = input.slice(0, 500);
                  }

                  setWords((pre) => ({
                    ...pre,
                    objective: input.length,
                  }));

                  handleChangeState("objective", input);
                }}
                onBlur={() => handleUpdate("objective", objective)}
                placeholder="Enter your objective"
                words={words?.objective}
              />

              <InputResize
                label="Purpose"
                value={purpose || ""}
                onChange={(value) => {
                  let input = value;

                  if (input.length > 500) {
                    input = input.slice(0, 500);
                  }

                  setWords((pre) => ({
                    ...pre,
                    purpose: input.length,
                  }));

                  handleChangeState("purpose", input);
                }}
                onBlur={() => handleUpdate("purpose", purpose)}
                placeholder="Enter your purpose"
                words={words?.purpose}
              />

              <InputResize
                label="Brainstorm Ideas"
                value={idea || ""}
                onChange={(value) => {
                  let input = value;

                  if (input.length > 500) {
                    input = input.slice(0, 500);
                  }

                  setWords((pre) => ({
                    ...pre,
                    idea: input.length,
                  }));

                  handleChangeState("idea", input);
                }}
                onBlur={() => handleUpdate("idea", idea)}
                placeholder="Enter your ideas"
                words={words?.idea}
              />
            </div>

            <div className={styles.form_right}>
              <div className={styles.inputGroup}>
                <label className={styles.label}>Start Date</label>

                <DatePicker
                  value={start_line}
                  name={"start_line"}
                  format={"MM/DD/YYYY"}
                  clearIcon={false}
                  style={{ width: "100%" }}
                  onChange={(date) => handleChange("start_line", date)}
                  placeholder="MM.DD.YYYY"
                  className="projectDetail-datepicker"
                />
              </div>

              <div className={styles.inputGroup}>
                <label className={styles.label}>End Date</label>
                <div className={styles.datePickerWrapper}>
                  <DatePicker
                    clearIcon={false}
                    format={"MM/DD/YYYY"}
                    value={dead_line ? dead_line : endDate}
                    disabledDate={(current) => {
                      return (
                        current && current < moment(start_line, "MM/DD/YYYY")
                      );
                    }}
                    style={{ width: "100%" }}
                    name={"dead_line"}
                    onChange={(date) => handleChange("dead_line", date)}
                    placeholder="MM/DD/YYYY "
                    className="projectDetail-datepicker"
                  />

                  {momentDeadLine && momentDeadLine < momentStartLine && (
                    <label className={styles.error}>
                      End date should be greater than start date
                    </label>
                  )}
                </div>
              </div>

              <div className={styles.duration}>
                <label className={styles.label}>Duration</label>

                <h6>
                  {moment(dead_line).diff(moment(start_line), "days") > 0
                    ? moment(dead_line).diff(moment(start_line), "days") +
                      " days"
                    : "--"}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.projectPlanDetails_notes}>
          <h2> Notes</h2>
          {planDetails?.goal?.notes?.length > 0 ? (
            <div className={styles.notesItems}>
              {planDetails?.goal?.notes?.map((note, index) => (
                <div className={styles.notesItem} key={index}>
                  <div>
                    <h5>{note?.context}</h5>

                    <span>{moment(note?.updated_at).fromNow()}</span>
                  </div>
                  <div className={styles.notes_actions}>
                    <EditIcon
                      onClick={() =>
                        handleEditNoteModal(note?.id, note?.context)
                      }
                    />
                    <DeleteIcon
                      onClick={() => handleRemoveNoteModal(note?.id)}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.noItems}>
              <h5>No notes available</h5>
            </div>
          )}

          <div className={styles.addNote}>
            <input
              placeholder="Add note"
              onChange={(e) => {
                let value = e.target.value;

                if (value.startsWith(" ")) {
                  value = value.trimStart();
                }

                setValue(value);
              }}
              value={value}
            />

            <button
              onClick={handleAddNote}
              loading={loadingNote}
              disabled={loadingNote || !value}
            >
              {loadingNote ? <LoadingOutlined /> : "Add"}
            </button>
          </div>
        </div>
      </div>

      <DeleteNoteModal
        visible={deleteNoteModal}
        heading={"Delete Note"}
        onClose={() => handleChangeMain("deleteNoteModal", false)}
        descriptions={"Are you sure you want to delete the note?"}
        handleRemove={handleRemoveNotePress}
        loading={loadingNoteDelete}
      />
    </>
  );
}
