import {
  CREATE_ACTION_ITEM_REQUEST,
  CREATE_NOTES_REQUEST,
  CREATE_PLAN_CATEGORY_REQUEST,
  CREATE_PLAN_REQUEST,
  CREATE_R_FILE_REQUEST,
  CREATE_TOOL_REQUEST,
  DELETE_ACTION_ITEM_REQUEST,
  DELETE_GOAL_REQUEST,
  DELETE_NOTES_REQUEST,
  DELETE_PLAN_CATEGORY_REQUEST,
  DELETE_R_FILE_REQUEST,
  DELETE_TOOL_REQUEST,
  GET_PLAN_BY_ID_REQUEST,
  GET_PLAN_REQUEST,
  REMOVE_PLAN_REQUEST,
  UPDATE_ACTION_ITEM_REQUEST,
  UPDATE_GOAL_REQUEST,
  UPDATE_NOTES_REQUEST,
  UPDATE_PLAN_CATEGORY_REQUEST,
  UPDATE_PLAN_REQUEST,
  UPDATE_RESOURCES_REQUEST,
  UPDATE_R_FILE_REQUEST,
  UPDATE_TOOL_REQUEST,
  CREATE_REMINDER_REQUEST,
  ARCHIVE_PLAN_REQUEST,
  ADJUST_CATEGORY_WEIGHT_REQUEST,
  GET_TOOL_REQUEST,
  CHANGE_ACTION_ITEM_ORDERS_REQUEST,
  CHANGE_PLAN_CATEGORY_ORDERS_REQUEST,
  CREATE_LABEL_AND_UNITS_REQUEST,
  CLONE_PLAN_REQUEST,
  GET_SHARED_PLAN_BY_ID_REQUEST,
  BULK_UPDATE_TOOL_REQUEST,
  GET_ALL_USER_REQUEST,
  UPDATE_COLLABORATOR_REQUEST,
  CREATE_REQUEST_ACCESS_REQUEST,
  CHANGE_CATEGORY_ORDERS_REQUEST,
  BULK_GOAL_UPDATE_REQUEST,
  UPADTE_GOAL_LABEL_REQUEST,
  GET_PROJECT_PREFERENCES_REQUEST,
  UPDATE_PROJECT_PREFERENCES_REQUEST,
  UPDATE_PROJECT_MANUAL_STATUS_REQUEST,
  CREATE_PROJECT_PREFERENCES_REQUEST,
  GET_GOAL_LIST_REQUEST,
  GET_GOAL_LIST_BY_ID_REQUEST,
  UPDATE_CUSTOMIZE_COLUMN_REQUEST,
  GET_CUSTOMIZE_COLUMN_REQUEST,
  CLEAR_SELECTED_GOAL_LIST,
  CREATE_NEW_ACTION_ITEM_REQUEST,
  UPLOAD_FILE_WITHOUT_PROJECTID_REQUEST,
  CREATE_PROJECT_PLAN_REQUEST,
  CLEAR_UPLOAD_FILE,
  BULK_UPDATE_PLAN_ACTION_CATEGORY_REQUEST,
  POST_METRIC_REQUEST,
  UPDATE_USER_TUTORIAL_REQUEST,
} from "../reducers/PlanReducer";

export const createPlan = (payload, closeModal, resetState) => ({
  type: CREATE_PLAN_REQUEST,
  payload,
  closeModal,
  resetState,
});

export const getPlans = (payload, spread, search) => ({
  type: GET_PLAN_REQUEST,
  payload: { payload, spread, search },
});

export const getPlanByID = (payload, callback) => ({
  type: GET_PLAN_BY_ID_REQUEST,
  payload,
  callback,
});

export const getSharedProjectByID = (payload, callback) => ({
  type: GET_SHARED_PLAN_BY_ID_REQUEST,
  payload,
  callback,
});

export const updatePlan = (id, payload, closeModal, noAlert) => ({
  type: UPDATE_PLAN_REQUEST,
  id,
  payload,
  closeModal,
  noAlert,
});

export const removePlan = (payload, goBack) => ({
  type: REMOVE_PLAN_REQUEST,
  payload,
  goBack,
});

export const archivePlan = (payload, goBack, closeModal) => ({
  type: ARCHIVE_PLAN_REQUEST,
  payload,
  goBack,
  closeModal,
});

export const createPlanCategory = (payload, removeNewList) => ({
  type: CREATE_PLAN_CATEGORY_REQUEST,
  payload,
  removeNewList,
});

export const updatePlanCategory = (id, payload, noAlert) => ({
  type: UPDATE_PLAN_CATEGORY_REQUEST,
  id,
  payload,
  noAlert,
});

export const adjustCategoryWeight = (payload, project_plan, closeModal) => ({
  type: ADJUST_CATEGORY_WEIGHT_REQUEST,
  closeModal,
  project_plan,
  payload,
});

export const deletePlanCategory = (payload, project_plan, closeModal) => ({
  type: DELETE_PLAN_CATEGORY_REQUEST,
  payload,
  project_plan,
  closeModal,
});

export const createActionItem = (payload, goBack) => ({
  type: CREATE_ACTION_ITEM_REQUEST,
  payload,
  goBack,
});

export const updateCustomizeColumn = (payload) => ({
  type: UPDATE_CUSTOMIZE_COLUMN_REQUEST,
  payload,
});

export const getCustomizeColumn = (payload) => ({
  type: GET_CUSTOMIZE_COLUMN_REQUEST,
  payload,
});

export const upateActionItem = (id, payload, project_plan, noAlert) => ({
  type: UPDATE_ACTION_ITEM_REQUEST,
  id,
  project_plan,
  payload,
  noAlert,
});

export const deleteActionItem = (payload, project_plan, closeModal) => ({
  type: DELETE_ACTION_ITEM_REQUEST,
  payload,
  project_plan,
  closeModal,
});

export const changeActionItemsOrder = (payload, project_plan) => ({
  type: CHANGE_ACTION_ITEM_ORDERS_REQUEST,
  payload,
  project_plan,
});

export const changeCategoryOrder = (payload, project_plan) => ({
  type: CHANGE_CATEGORY_ORDERS_REQUEST,
  payload,
  project_plan,
});

export const changeCategoryItemsOrder = (payload, project_plan) => ({
  type: CHANGE_PLAN_CATEGORY_ORDERS_REQUEST,
  payload,
  project_plan,
});

export const createNotes = (payload, project_plan, goBack) => ({
  type: CREATE_NOTES_REQUEST,
  payload,
  project_plan,
  goBack,
});

export const createNewActionItemsModal = (
  payload,
  goBack,
  project_plan,
  setActionItemsModal
) => ({
  type: CREATE_NEW_ACTION_ITEM_REQUEST,
  payload,
  project_plan,
  setActionItemsModal,
});

export const upateNotes = (id, payload, project_plan, callback) => ({
  type: UPDATE_NOTES_REQUEST,
  id,
  project_plan,
  payload,
  callback,
});

export const deleteNotes = (payload, project_plan, closeModal) => ({
  type: DELETE_NOTES_REQUEST,
  payload,
  project_plan,
  closeModal,
});

export const getTools = () => ({
  type: GET_TOOL_REQUEST,
});

export const getAllRequest = (payload) => ({
  type: GET_ALL_USER_REQUEST,
  payload,
});

export const updateRequest = (payload, id) => ({
  type: UPDATE_COLLABORATOR_REQUEST,
  payload,
  id,
});

export const createCollaboratorRequest = (payload, navigate) => ({
  type: CREATE_REQUEST_ACCESS_REQUEST,
  payload,
  navigate,
});

export const createTool = (payload, closeModal) => ({
  type: CREATE_TOOL_REQUEST,
  payload,
  closeModal,
});

export const bulkUpdateTool = (payload, id, closeModal) => ({
  type: BULK_UPDATE_TOOL_REQUEST,
  payload,
  id,
  closeModal,
});

export const upateTool = (id, payload, project_plan) => ({
  type: UPDATE_TOOL_REQUEST,
  id,
  project_plan,
  payload,
});

export const deleteTool = (payload, project_plan) => ({
  type: DELETE_TOOL_REQUEST,
  payload,
  project_plan,
});

export const createRFile = (payload, project_plan) => ({
  type: CREATE_R_FILE_REQUEST,
  payload,
  project_plan,
});

export const upateRFile = (id, payload, project_plan) => ({
  type: UPDATE_R_FILE_REQUEST,
  id,
  project_plan,
  payload,
});

export const updateResources = (
  id,
  payload,
  project_plan,
  closeModal,
  message
) => ({
  type: UPDATE_RESOURCES_REQUEST,
  id,
  project_plan,
  payload,
  closeModal,
  message,
});

export const deleteRFile = (payload, project_plan) => ({
  type: DELETE_R_FILE_REQUEST,
  payload,
  project_plan,
});

export const updateGoal = (id, payload, project_plan, key) => ({
  type: UPDATE_GOAL_REQUEST,
  id,
  project_plan,
  payload,
  key,
});

export const PostMetric = (payload, project_plan, handleClose) => ({
  type: POST_METRIC_REQUEST,
  payload,
  project_plan,
  handleClose,
});
export const blukUpdateGoal = (payload, project_plan, handleClose) => ({
  type: BULK_GOAL_UPDATE_REQUEST,
  payload,
  project_plan,
  handleClose,
});

export const updateGoalLabel = (payload) => ({
  type: UPADTE_GOAL_LABEL_REQUEST,
  payload,
});

export const deleteGoal = (id, project_plan) => ({
  type: DELETE_GOAL_REQUEST,
  id,
  project_plan,
});

export const createReminder = (payload, project_plan, closeModal) => ({
  type: CREATE_REMINDER_REQUEST,
  payload,
  project_plan,
  closeModal,
});

export const createCustomLabelAndUnits = (
  payload,
  selectedUnitValue,
  project_plan,
  closeModal
) => ({
  type: CREATE_LABEL_AND_UNITS_REQUEST,
  payload,
  project_plan,
  selectedUnitValue,
  closeModal,
});

export const clonePlan = (payload, callback) => ({
  type: CLONE_PLAN_REQUEST,
  payload,
  callback,
});

export const getProjectPerferences = (payload) => ({
  type: GET_PROJECT_PREFERENCES_REQUEST,
  payload,
});

export const createProjectPerferences = (payload) => ({
  type: CREATE_PROJECT_PREFERENCES_REQUEST,
  payload,
});

export const updateProjectPerferences = (id, payload) => ({
  type: UPDATE_PROJECT_PREFERENCES_REQUEST,
  id,
  payload,
});

export const updateManualStatus = (
  id,
  payload,
  projectPlanId,
  handleClose
) => ({
  type: UPDATE_PROJECT_MANUAL_STATUS_REQUEST,
  id,
  payload,
  projectPlanId,
  handleClose,
});

export const getGaolList = (payload) => ({
  type: GET_GOAL_LIST_REQUEST,
});

export const getGoalListById = (payload) => ({
  type: GET_GOAL_LIST_BY_ID_REQUEST,
  payload,
});

export const clearSelectedGoalList = () => ({
  type: CLEAR_SELECTED_GOAL_LIST,
});

export const clearUploadFile = () => ({
  type: CLEAR_UPLOAD_FILE,
});

export const updateFile = (payload) => ({
  type: UPLOAD_FILE_WITHOUT_PROJECTID_REQUEST,
  payload,
});

export const createProjectPlan = (payload, closeModal) => ({
  type: CREATE_PROJECT_PLAN_REQUEST,
  payload,
  closeModal,
});

export const bulkUpdatePlanActionCategory = (payload, closeModal) => ({
  type: BULK_UPDATE_PLAN_ACTION_CATEGORY_REQUEST,
  payload,
  closeModal,
});

export const UpdateTutorial = (id, payload) => ({
  type: UPDATE_USER_TUTORIAL_REQUEST,
  id,
  payload,
});
