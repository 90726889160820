import React, { useEffect, useState } from "react";
import { Col, Collapse, Row } from "antd";
import styled from "styled-components";

import { CaretRightOutlined, CaretDownOutlined } from "@ant-design/icons";
import { ReactComponent as MiniRight } from "./Chevron-Mini-Right.svg";
import { ReactComponent as MiniDown } from "./Chevron-Mini-Down.svg";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getDescriptionsSummary } from "api";
import { Select } from "scenes/DecisionMatrix/components";
import { UpdateCriteriaRows } from "../../../../redux/DecisionMatrix/UpdateCriteriaRows/action"

const CriteriaComparisonLeft = ({
  leftOption,
  rightOption,
  activeCriterias,
  /* isUpdateMe,
  setIsUpdateMe, */
  isTrue,
  setIsTrue,
  isUpdate,
  activeKey,
  setActiveKey,
}) => {
  const { Panel } = Collapse;
  // const [isSelect, setIsSelect] = useState(false);

  const customExpandIcon = ({ isActive }) => {
    if (isActive) {
      return <CaretDownOutlined />;
    } else {
      return <CaretRightOutlined />;
    }
  };
  const { decisionId } = useParams();
  const dispatch = useDispatch();
  const { success: userDecisions, loading } = useSelector(
    (state) => state.getDecisionDetailsReducer
  );

  const ProsCons = ({ items }) => {
    const [leftComponentData, setLeftComponentData] = useState({});
    const [rightComponentData, setRightComponentData] = useState({});

    let leftRes = leftOption?.option_criteria?.find(
      (item) => item?.criteria === items?.id
    );

    let rightRes = rightOption?.option_criteria?.find(
      (item) => item?.criteria === items?.id
    );

    useEffect(() => {
      const fetchDataAndAddInstance = async (optionId) => {
        try {
          const { data } = await getDescriptionsSummary(optionId); // Fetch data from your API

          // Add the new instance to the appropriate componentData array
          if (optionId === leftRes?.option) {
            setLeftComponentData((leftComponentData) => {
              return { ...leftComponentData, ...data };
            });
          } else if (optionId === rightRes?.option) {
            setRightComponentData((rightComponentData) => {
              return { ...rightComponentData, ...data };
            });
          } else {
            console.error(
              `Unable to match optionId: ${optionId} with leftOption or rightOption`
            );
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      if (leftRes) {
        fetchDataAndAddInstance(leftRes?.option);
      }
      if (rightRes) {
        fetchDataAndAddInstance(rightRes?.option);
      }
    }, []);

    const filteredLeftComponentDataPro = (compData) => {
      if (!compData) return;
      return compData?.filter((data) => data?.criteria_id === items?.id);
      // return res;
    };

    const filteredLeftComponentDataCon = (compData) => {
      if (!compData) return;
      return compData?.filter((data) => data?.criteria_id === items?.id);
    };

    const filteredRightComponentDataPro = (compData) => {
      if (!compData) return;
      return compData?.filter((data) => data?.criteria_id === items?.id);
    };

    const filteredRightComponentDataCon = (compData) => {
      if (!compData) return;
      return compData?.filter((data) => data?.criteria_id === items?.id);
    };
    // console.log({ leftRes, rightRes });
    // console.log(filteredLeftComponentDataPro(leftComponentData[0]?.pros));

    return (
      <TextContainer>
        <>
          <FactsHeading>Facts</FactsHeading>
          <TextWrapper>
            {leftRes && <Text>{leftRes?.facts}</Text>}
            {/* {rightRes && <Text>{rightRes?.facts}</Text>} */}
          </TextWrapper>
        </>
        <ConsContainer>
          <SubHeading>
            Pros{" "}
            <Span isWhite>
              {filteredLeftComponentDataPro(leftComponentData?.pros)?.length}/
              {filteredRightComponentDataPro(leftComponentData?.cons)?.length}
            </Span>
          </SubHeading>
          {/* <ConsHeader>
          <Pros>Pros</Pros>
        </ConsHeader> */}

          <Row>
            <Col span={24}>
              <ConsHeader isBorder>
                <Pros>Pros</Pros>
              </ConsHeader>
              {/* {leftComponentData &&
                filteredLeftComponentDataPro(leftComponentData?.pros)?.map(
                  (item, index) => (
                    <ListWrapper
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#f1f1f1" : "#ffffff",
                      }}
                    >
                      <ContWrapper>
                        <Count>{index + 1}.</Count>
                        <ListContent>
                          <Small>{item?.criteria_title}</Small>
                          <Para>{item?.context}</Para>
                        </ListContent>
                      </ContWrapper>
                      <High>
                        <CircleTwo
                          grey={item?.priority === 0}
                          green={item?.priority === 3}
                          red={item?.priority === 1}
                          orange={item?.priority === 2}
                        ></CircleTwo>
                        <SmallText>
                          {(item?.priority === 0 && "None") ||
                            (item?.priority === 1 && "Low") ||
                            (item?.priority === 2 && "Medium") ||
                            (item?.priority === 3 && "High")}
                        </SmallText>
                      </High>
                    </ListWrapper>
                  )
                )} */}
              {filteredLeftComponentDataPro(leftComponentData?.pros)?.map(
                (item, index) => (
                  <ListWrapper
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f1f1f1" : "#ffffff",
                    }}
                  >
                    <ContWrapper>
                      <Count>{index + 1}.</Count>
                      <ListContent>
                        {/* <Small>{item?.criteria_title}</Small> */}
                        <Para>{item?.context}</Para>
                      </ListContent>
                    </ContWrapper>
                    <High>
                      <CircleTwo
                        grey={item?.priority === 0}
                        green={item?.priority === 3}
                        red={item?.priority === 1}
                        orange={item?.priority === 2}
                      ></CircleTwo>
                      <SmallText>
                        {(item?.priority === 0 && "None") ||
                          (item?.priority === 1 && "Low") ||
                          (item?.priority === 2 && "Medium") ||
                          (item?.priority === 3 && "High")}
                      </SmallText>
                    </High>
                  </ListWrapper>
                )
              )}
            </Col>
            {/* <Col span={12}>
              <ConsHeader>
                <Pros></Pros>
              </ConsHeader>
              {filteredRightComponentDataPro(rightComponentData?.pros)?.map(
                (item, index) => (
                  <ListWrapper
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f1f1f1" : "#ffffff",
                    }}
                  >
                    <ContWrapper>
                      <Count>{index + 1}.</Count>
                      <ListContent>
                        <Small>{item?.criteria_title}</Small>
                        <Para>{item?.context}</Para>
                      </ListContent>
                    </ContWrapper>
                    <High>
                      <CircleTwo
                        grey={item?.priority === 0}
                        green={item?.priority === 3}
                        red={item?.priority === 1}
                        orange={item?.priority === 2}
                      ></CircleTwo>
                      <SmallText>
                        {(item?.priority === 0 && "None") ||
                          (item?.priority === 1 && "Low") ||
                          (item?.priority === 2 && "Medium") ||
                          (item?.priority === 3 && "High")}
                      </SmallText>
                    </High>
                  </ListWrapper>
                )
              )}
            </Col> */}
          </Row>
        </ConsContainer>
        <ConsContainer>
          <SubHeading>
            Cons{" "}
            <Span isWhite>
              {filteredLeftComponentDataCon(leftComponentData?.cons)?.length}/
              {filteredRightComponentDataCon(leftComponentData?.pros)?.length}
            </Span>
          </SubHeading>

          <Row>
            <Col span={24}>
              <ConsHeader isPink isBorder>
                <Pros>Cons</Pros>
              </ConsHeader>
              {/* {filteredLeftComponentDataCon(leftComponentData?.cons)?.map(
                (item, index) => (
                  <ListWrapper
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f1f1f1" : "#ffffff",
                    }}
                  >
                    <ContWrapper>
                      <Count>{index + 1}.</Count>
                      <ListContent>
                        <Small>{item?.criteria_title}</Small>

                        <Para>{item?.context}</Para>
                      </ListContent>
                    </ContWrapper>
                    <High>
                      <CircleTwo
                        grey={item?.priority === 0}
                        green={item?.priority === 3}
                        red={item?.priority === 1}
                        orange={item?.priority === 2}
                      ></CircleTwo>
                      <SmallText>
                        {(item?.priority === 0 && "None") ||
                          (item?.priority === 1 && "Low") ||
                          (item?.priority === 2 && "Medium") ||
                          (item?.priority === 3 && "High")}
                      </SmallText>
                    </High>
                  </ListWrapper>
                )
              )} */}
              {filteredLeftComponentDataCon(leftComponentData?.cons)?.map(
                (item, index) => (
                  <ListWrapper
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f1f1f1" : "#ffffff",
                    }}
                  >
                    <ContWrapper>
                      <Count>{index + 1}.</Count>
                      <ListContent>
                        {/* <Small>{item?.criteria_title}</Small> */}
                        <Para>{item?.context}</Para>
                      </ListContent>
                    </ContWrapper>
                    <High>
                      <CircleTwo
                        grey={item?.priority === 0}
                        green={item?.priority === 3}
                        red={item?.priority === 1}
                        orange={item?.priority === 2}
                      ></CircleTwo>
                      <SmallText>
                        {(item?.priority === 0 && "None") ||
                          (item?.priority === 1 && "Low") ||
                          (item?.priority === 2 && "Medium") ||
                          (item?.priority === 3 && "High")}
                      </SmallText>
                    </High>
                  </ListWrapper>
                )
              )}
            </Col>
            {/* <Col span={12}>
              <ConsHeader isPink>
                <Pros></Pros>
              </ConsHeader>
              {filteredRightComponentDataCon(rightComponentData?.cons)?.map(
                (item, index) => (
                  <ListWrapper
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f1f1f1" : "#ffffff",
                    }}
                  >
                    <ContWrapper>
                      <Count>{index + 1}.</Count>
                      <ListContent>
                        <Small>{item?.criteria_title}</Small>

                        <Para>{item?.context}</Para>
                      </ListContent>
                    </ContWrapper>
                    <High>
                      <CircleTwo
                        grey={item?.priority === 0}
                        green={item?.priority === 3}
                        red={item?.priority === 1}
                        orange={item?.priority === 2}
                      ></CircleTwo>
                      <SmallText>
                        {(item?.priority === 0 && "None") ||
                          (item?.priority === 1 && "Low") ||
                          (item?.priority === 2 && "Medium") ||
                          (item?.priority === 3 && "High")}
                      </SmallText>
                    </High>
                  </ListWrapper>
                )
              )}
            </Col> */}
          </Row>
        </ConsContainer>
      </TextContainer>
    );
  };

  const Header = ({ item, index, onToggle }) => {
    let leftScore = leftOption?.option_criteria?.find(
      (items) => items?.criteria === item?.id
    );
    let rightScore = rightOption?.option_criteria?.find(
      (items) => items?.criteria === item?.id
    );

    useEffect(() => {
      isUpdate();
    }, [leftOption]);

    const handleRubricSave = (rubrics, criteria) => {
      dispatch(
        UpdateCriteriaRows({
          update: userDecisions?.criteria
            ?.filter((crit) => crit?.id === criteria)
            ?.map(({ rubric, ...rest }) => {
              return { ...rest, rubric: rubrics };
            }),
          decision: decisionId,
        })
      );
    }

    return (
      <>
        <HeaderContent
          onClick={() => onToggle(index)}
          style={{ backgroundColor: activeKey === index ? "#f4f4f4" : "white" }}
        >
          <SubHeading>
            {activeKey === index ? (
              // <CaretDownOutlined />
              <MiniDown />
            ) : (
              // <CaretRightOutlined />
              <MiniRight />
            )}
            {index + 1}
            {<Span>{item?.weight}%</Span>}
            <CriteriaHeading>{item?.title}{" "}</CriteriaHeading>
          </SubHeading>

          <ScoreWrap
            onClick={(e) => {
              // Prevent the event from propagating to the Collapse panel
              e.stopPropagation();
            }}
          >
            {/* {leftScore?.second_stage_score !== "0.0" ? (
              <ScoreVal>
                <del>{Math.round(leftScore?.score)}</del>
              </ScoreVal>
            ) : null} */}
            {/* <Select  /> */}
            <Select
              isBackground={
                leftScore?.second_stage_score > rightScore?.second_stage_score
                  ? true
                  : false
              }
              isLeft={true}
              // value={leftScore?.second_stage_score}
              optionCriteria={leftScore || {}}
              curentIndex={false}
              secondary
              // isShortList={leftScore?.second_stage_score ? true : false}
              isShortList={
                leftScore?.second_stage_score === "0.0" ? false : true
              }
              rubric={item?.rubric}
              // setIsUpdateMe={setIsUpdateMe}
              // isUpdateMe={isUpdateMe}
              setIsTrue={setIsTrue}
              isTrue={isTrue}
              onRubricSave={handleRubricSave}
            />
          </ScoreWrap>
        </HeaderContent>
      </>
    );
  };

  const handleCollapseChange = (key) => {
    setActiveKey((prevKey) => (prevKey === key ? null : key));
  };

  return (
    <>
      {/* {activeCriterias?.map((item, i) => (
        <Wrapper isSelect>
          <Collapse
            key={i}
            expandIcon={customExpandIcon}
            // collapsible="icon"
            showArrow={"none"}
            bordered={true}
            activeKey={activeKey}
            onChange={() => handleCollapseChange(i)}
          >
            <Panel
              onClick={() => setIsSelect(true)}
              header={
                <Header
                  item={item}
                  index={i}
                  onHeaderClick={handleCollapseChange}
                />
              }
              key={i}
            >
              <ProsCons items={item} />
            </Panel>
          </Collapse>
        </Wrapper>
      ))} */}
      <Wrapper>
        {activeCriterias?.map((item, i) => (
          <Collapse
            key={i}
            expandIcon={customExpandIcon}
            bordered={true}
            showArrow={"none"}
            activeKey={activeKey}
            // onChange={() => handleCollapseChange(i)}
          >
            {/* <Panel header={<Header item={item} index={i} />} key={i}> */}
            <Panel
              header={
                <Header
                  item={item}
                  index={i}
                  activeKey={activeKey}
                  onToggle={handleCollapseChange}
                />
              }
              key={i}
            >
              <ProsCons items={item} />
            </Panel>
          </Collapse>
        ))}
      </Wrapper>
    </>
  );
};

export default CriteriaComparisonLeft;
const Wrapper = styled.div`
  width: 100%;
  /* margin-bottom: 1rem; */
  /* border: 2px solid yellow; */
  /* margin-top: 1rem; */
  .ant-collapse-header {
    /* background-color: #ffff; */
    width: 100%;
    display: flex !important;
    align-items: center !important;
    padding: 0 !important;

    .ant-collapse-expand-icon {
      transform: translateX(16px);
    }
  }
  .ant-collapse-expand-icon {
    /* transform: translateY(-3px);
     */
    display: none;
  }
`;
const SmallText = styled.p`
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  padding: 0;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* padding: 0 2rem; */
  height: auto;
  width: 100%;
  padding: 12px 16px;
`;
const SubHeading = styled.h2`
  flex:7;
  margin: 0;
  padding: 0;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13.5px;
  width: 80%;
  display: flex;
  gap: 0.3rem;
  align-items: center;
  line-height: 18px;

`;
const Span = styled.div`
  margin-left: 4px;
  width: 32px;
  height: 26px;
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: ${({ isWhite }) => (isWhite ? "#000" : "#fafafa")};
  background-color: ${({ isWhite }) => (isWhite ? "#f4f4f4" : "#418db8")};
  display: inline-block;
  border-radius: 3px;
  padding: 0.22rem;
  text-align: center;
  vertical-align: middle;
`;
const ScoreWrap = styled.div`
  flex:1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  width: 50%;
`;
const CriteriaHeading = styled.span`
  max-width: 80%;
  word-break: break-word;
`
const Circle = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${({ grey, green, red, orange }) =>
    (grey && "#cdcdcd") ||
    (green && "#89c73f") ||
    (orange && "#feab3d") ||
    (red && "#f00001")};
  border-radius: 50%;
  color: ${({ grey }) => (grey ? "#000000" : "#ffffff")};
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const IconWrapper = styled.div`
  display: flex;
  align-self: center;
`;
const Icons = styled.div`
  transform: translateX(-30px);
`;
const TextContainer = styled.div`
  padding: 1rem;
`;
const FactsHeading = styled.h2`
  margin: 0;
  padding: 0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #1b2a3d;
  height: 20px;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0.8rem;
  width: 100%;
`;

const Text = styled.div`
  width: 100%;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #4b545b;
`;
const ConsContainer = styled.div`
  margin-top: 1.2rem;
`;
const ConsHeader = styled.div`
  width: 100%;
  height: 40px;
  padding: 0 1rem;
  background-color: ${({ isPink }) => (isPink ? "#ff8071" : "#89c740")};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  margin-top: 1rem;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border-right: ${({ isBorder }) => (isBorder ? "1px solid #fff" : "none")};
`;
const ListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  height: 88px;
  width: 100%;
  border-right: 1px solid #f5f5f5;
  padding: 1rem;
`;
const Count = styled.div`
  color: #a3a3a3;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
const ListContent = styled.div``;
const High = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
`;
const Small = styled.p`
  padding: 0;
  margin: 0;
  color: #1271a6;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
`;
const Para = styled.p`
  padding: 0;
  margin: 0;
  color: #404040;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;
const CircleTwo = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ grey, green, red, orange }) =>
    (grey && "#8e97a3") ||
    (green && "#4b8120") ||
    (red && "#f00001") ||
    (orange && "#feab3c")};
`;

const ContWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
`;
const Pros = styled.p`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  padding-top: 0.5rem;
`;
const ScoreVal = styled.p`
  margin: 0;
  padding: 0;
`;