import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Card,
  Tag,
  Upload,
  Button,
  Popover,
  Divider,
  Modal,
  Dropdown,
} from "antd";
import {
  CloseOutlined,
  PlusOutlined as PlusOutlinedAnt,
} from "@ant-design/icons";
import { ReactComponent as PlusOutlined } from "assets/svg/Plus.svg";
import { ReactComponent as SmallEdit } from "assets/svg/smallEdit.svg";
import { ReactComponent as Cross } from "assets/svg/close.svg";
import { COLORS } from "constants/colors";
import AppInput from "components/AppInput";
import { validateEmail } from "utils/utility";
import { useDispatch, useSelector } from "react-redux";
import { createTool } from "modules/actions/PlanActions";
import { FileIcon } from "react-file-icon";
import { Collapse } from "antd";

import PublicLink from "./components/PublicLink";

import InviteUserModal from "./components/InviteUserModal";
import { toast } from "react-toastify";
import ToolsEdit from "./components/ToolsEdit";
import {
  bulkUpdateTool,
  updateRequest,
  updateResources,
} from "../../../modules/actions/PlanActions";
import { inviteOwner } from "modules/actions/UserActions";

// Styles
import styles from "./styles.module.scss";

// Icons
import { ReactComponent as BackIcon } from "assets/svg/BackPrimary.svg";
import { ReactComponent as EditGray } from "assets/svg/editGray.svg";
import { ReactComponent as PlusBlue } from "assets/svg/plusBlue.svg";
import { ReactComponent as MenuDots } from "assets/svg/menudots.svg";
import { ReactComponent as File } from "assets/svg/file.svg";

export default function Resources({
  handleChange,
  handleRemoveTool,
  planDetails,
  handleAddRFile,
  handleRemoveRFile,
  users,
  selectedUsers,
  handleSubmit,
  loading,
  peoplePop,
  requestPop,
  toolPop,
  tools,
  selectedTools,
  isNewUser,
  setIsNewUser,
}) {
  const { Panel } = Collapse;

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const payload = new FormData();
      payload.append("url", file);
      payload.append("project_plan", planDetails?.id);
      handleAddRFile(payload);
    }
  };

  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [initialUsers, setInitialUsers] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const { loading: inviteLoading } = useSelector(
    (state) => state.user.invitedUser
  );

  const [isEditTools, setIsEditTools] = useState(false);
  const [editTool, setEditTool] = useState(null);

  const [isVerifiedEmail, setIsVerifiedEmail] = useState(false);
  const [emailToInvite, setEmailToInvite] = useState(null);
  const [inviteEmail, setInviteEmail] = useState(null);
  const [registrationLoading, setRegistrationLoading] = useState(false);
  const [collaboratorRequests, setCollaboratorRequests] = useState([]);

  const {
    loading: invitePlan,
    newtool,
    allRequest,
    loadingUpdateBulkTodo,
  } = useSelector((state) => state.plan.plan);

  const { user } = useSelector((state) => state.auth);
  const [state, setState] = useState({
    allTools: tools || [],
    allUsers: users || [],
    searchTool: "",
    searchUser: "",
    disabledToolButton: true,
    disabledPeopleButton: true,
  });

  const {
    allTools,
    allUsers,
    searchTool,
    searchUser,
    disabledToolButton,
    disabledPeopleButton,
  } = state;

  const handleChangeLocal = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };
  const handleChangeFile = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const setFileFormat = () => {
    const list = [];
    planDetails?.resource?.files?.map((file) => {
      list.push({
        uid: file?.id,
        name: get_url_name(file?.url),
        status: "done",
        url: file?.url,
      });
    });
    return list || [];
  };

  useEffect(() => {
    if (planDetails) {
      setFileList(setFileFormat());
    }
  }, [planDetails]);

  useEffect(() => {
    if (planDetails?.resource?.people) {
      setInitialUsers(planDetails.resource.people);
    }
  }, [planDetails]);

  useEffect(() => {
    if (selectedUsers) {
      checkAnyChange();
    }
  }, [selectedUsers]);

  useEffect(() => {
    if (allRequest) {
      setCollaboratorRequests(allRequest);
    }
  }, [allRequest]);

  useEffect(() => {
    if (!peoplePop && !toolPop) {
      handleChangeLocal("searchUser", "");
      handleChangeLocal("searchTool", "");
    }

    if (users) {
      handleChangeLocal("allUsers", users);
      handleChangeLocal("allTools", tools);
    }
  }, [users, tools, peoplePop, toolPop]);

  function get_url_extension(url) {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  }

  function get_url_title(url) {
    const parts = url.split("/resources/");

    if (parts.length > 1) {
      const fileNameWithExtension = parts[1].split(/[#?]/)[0];
      const fileName = fileNameWithExtension.split(".").slice(0, -1).join(".");
      return fileName;
    }

    return null;
  }

  function get_url_name(url) {
    const a = url?.split("/");
    const b = a?.length > 0 && a[a?.length - 1];
    return b;
  }

  const props = {
    headers: {
      authorization: "authorization-text",
    },
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    name: "file",
    listType: "picture-card",
    fileList: fileList,
  };

  const removeAlready = () => {
    const removed = allUsers?.filter(
      (elem) => !selectedUsers?.find(({ id }) => elem.id === id)
    );
    return removed;
  };

  const removeToolsAlready = () => {
    const removed = allTools?.filter(
      (elem) => !selectedTools?.find(({ id }) => elem.id === id)
    );
    return removed;
  };

  const checkSameTool = (value) => {
    const found = allTools?.some((elem) => elem?.name === value);
    return found;
  };

  const handleAddSelectedUsers = (owner) => {
    // if (owner?.email === user?.data?.email) {
    //   toast.error("You cannot add yourself");
    //   return;
    // }

    handleChangeLocal("disabledPeopleButton", false);
    if (selectedUsers?.some((e) => e?.id === owner?.id)) {
      const removed = selectedUsers?.filter((e) => e?.id !== owner?.id);
      handleChange("selectedUsers", removed);
    } else {
      handleChange("selectedUsers", [...selectedUsers, owner]);
    }
  };

  const handleAddSelectedTools = (tool) => {
    handleChangeLocal("disabledToolButton", false);
    if (selectedTools?.some((e) => e?.id === tool?.id)) {
      const removed = selectedTools?.filter((e) => e?.id !== tool?.id);
      handleChange("selectedTools", removed);
    } else {
      handleChange("selectedTools", [...selectedTools, tool]);
    }
  };

  const handleAddPeople = () => {
    const ids = [];
    selectedUsers.forEach((element) => {
      ids?.push(element?.id);
    });
    const payload = { people: ids };
    handleSubmit(payload, "Peoples has been updated");
    handleChangeLocal("disabledPeopleButton", true);
  };

  const handleAddTool = () => {
    const ids = [];
    selectedTools.forEach((element) => {
      ids?.push(element?.id);
    });
    const payload = { tools: ids };
    handleSubmit(payload, "Tools has been updated");
    handleChangeLocal("disabledToolButton", true);
  };

  const filtered = (key, value, isTool) => {
    let newValue = value;

    if (newValue.length > 60) newValue = newValue.substring(0, 60);

    handleChangeLocal(key, newValue);

    const data = isTool ? tools : users;
    const selectedState = isTool ? "allTools" : "allUsers";

    if (newValue) {
      // const re = new RegExp(newValue, "i");
      var filtered = data?.filter((entry) =>
        entry[!isTool ? "email" : "name"]
          ?.toLowerCase()
          ?.includes(newValue?.toLowerCase())
      );

      handleChangeLocal(selectedState, filtered);
    } else {
      handleChangeLocal(selectedState, data);
    }
  };

  const handleAddNewTool = () => {
    const payload = {
      name: searchTool,
      project_plan: planDetails?.id,
    };

    dispatch(createTool(payload, handleOwnerModal));
  };

  const handleInvite = () => {
    if (user?.data?.email === searchUser) {
      toast.error("You cannot invite yourself");
      return;
    }
    setEmailToInvite(searchUser);
    setInviteEmail(searchUser);
    setState((pre) => ({ ...pre, searchUser: "" }));
    handleChange("peoplePop", false);
  };

  const handleOwnerModal = () => {
    // handleChange('peoplePop', false)
    handleChangeLocal("searchUser", "");
    handleChangeLocal("searchTool", "");
  };

  const handleOwnerInviteModal = () => {
    // if (searchUser) {
    // }
    // handleChangeLocal("searchUser", "");
  };

  useEffect(() => {
    if (newtool && newtool?.id) {
      handleAddSelectedTools(newtool);
    }
  }, [newtool]);

  const checkAnyChange = () => {
    if (!selectedUsers || !initialUsers) return;

    const initialUserIDs = initialUsers.map((user) => user.id);
    const selectedUserIDs = selectedUsers.map((user) => user.id);

    const hasChanged =
      initialUserIDs.length !== selectedUserIDs.length ||
      initialUserIDs.some((id) => !selectedUserIDs.includes(id)) ||
      selectedUserIDs.some((id) => !initialUserIDs.includes(id));

    setHasChanges(hasChanged);
  };

  const items = (file) => [
    {
      label: "View",
      key: "1",
      onClick: (e) => {
        window.open(file?.url, "_blank");
      },
    },
    {
      label: "Remove",
      key: "0",
      onClick: (e) => {
        handleRemoveRFile(file?.uid);
      },
      danger: true,
    },
  ];

  const uploadButton = (
    <div className="addattachmentPlus">
      <PlusOutlinedAnt />
    </div>
  );

  const handleCloseEditModel = () => {
    setIsEditTools(false);
    handleChange("toolPop", false);
    setEditTool(null);
    setIsVerifiedEmail(false);
    setInviteEmail(null);
    setRegistrationLoading(false);
  };

  useEffect(() => {
    if (planDetails?.resource?.tools) setEditTool(planDetails?.resource?.tools);
  }, [planDetails?.resource?.tools, isEditTools]);

  const closeModal = () => {};

  const handleUpdateTool = () => {
    const payload = editTool.map((tool) => {
      return {
        id: tool?.id,
        name: tool?.name,
      };
    });

    const ids = editTool?.map((item) => item?.id);
    const payloadUpdate = { tools: ids };
    handleChange("selectedTools", editTool);

    dispatch(
      updateResources(
        planDetails?.resource?.id,
        payloadUpdate,
        planDetails?.id,
        closeModal,
        "Tool Deleted Successfully"
      )
    );

    dispatch(bulkUpdateTool(payload, planDetails?.id, handleCloseEditModel));
  };

  const handleInviteUser = () => {
    setRegistrationLoading(true);
    const payload = {
      invited_owner: inviteEmail,
      project_id: planDetails?.id,
    };
    const qs = ``;
    dispatch(inviteOwner(payload, handleCloseEditModel, qs));
  };

  const handleApprove = (e, item) => {
    e.stopPropagation();
    const filterData = collaboratorRequests.filter(
      (request) => request.id !== item.id
    );
    setCollaboratorRequests(filterData);

    const payload = {
      status: 2,
    };

    dispatch(updateRequest(payload, item?.id));
  };

  const handleDeny = (e, item) => {
    e.stopPropagation();
    const filterData = collaboratorRequests.filter(
      (request) => request.id !== item.id
    );
    setCollaboratorRequests(filterData);

    const payload = {
      status: 3,
    };

    dispatch(updateRequest(payload, item?.id));
  };

  return (
    <>
      <InviteUserModal
        isActive={!!emailToInvite}
        emailToInvite={emailToInvite ?? ""}
        setIsVerifiedEmail={setIsVerifiedEmail}
        onClose={() => setEmailToInvite(null)}
        handleChange={handleChange}
        selectedUsers={selectedUsers}
        setHasChanges={setHasChanges}
        isNewUser={isNewUser}
        setIsNewUser={setIsNewUser}
        handleChangeLocal={handleChangeLocal}
      />

      <Modal
        footer={null}
        closable={false}
        onCancel={handleCloseEditModel}
        title={false}
        open={isVerifiedEmail}
        centered
        width={450}
        className="disablePadding"
      >
        <div className="p-24">
          <Row justify="space-between mb-4">
            <div className="font-18 text_black text_500">
              Invite collaborators to your project
            </div>
            <Cross className={"c-pointer"} onClick={handleCloseEditModel} />
          </Row>

          <h6
            style={{
              fontSize: 12,
              fontWeight: 400,
              color: "#737373",
              marginBottom: 30,
            }}
          >
            {inviteEmail}
          </h6>

          <p
            style={{
              fontSize: 14,
              fontWeight: 400,
              color: "#404040",
            }}
          >
            The added collaborator doesn’t have a Project Manager account. Email
            a registration link to create an account and get added to your
            project.
          </p>
        </div>

        <Row
          justify="end"
          style={{
            backgroundColor: "#f5f5f5",
            padding: "16px 24px",
          }}
        >
          <Button
            type="primary"
            loading={registrationLoading}
            disabled={registrationLoading}
            onClick={handleInviteUser}
            className="inviteCollaboratorBtn"
          >
            {"Send Registration link"}
          </Button>
        </Row>
      </Modal>

      <Modal
        footer={null}
        closable={false}
        onCancel={handleCloseEditModel}
        title={false}
        open={isEditTools}
        centered
        width={400}
        className="disablePadding"
      >
        <div className="p-24">
          <Row justify="space-between mb-4">
            <div className="font-18 text_black text_500">Edit Tool</div>
            <Cross className={"c-pointer"} onClick={handleCloseEditModel} />
          </Row>

          <div className="toolsUpdateModel">
            {editTool?.length > 0 ? (
              editTool?.map((tool, index) => (
                <ToolsEdit
                  tool={tool}
                  index={index}
                  editTool={editTool}
                  setEditTool={setEditTool}
                  id={planDetails?.id}
                  planDetails={planDetails}
                  handleChange={handleChange}
                />
              ))
            ) : (
              <div className="empty-div">No tools added yet</div>
            )}
          </div>
        </div>

        <Divider
          className="width110"
          style={{
            margin: "10px 0px",
          }}
        />
        <Row
          justify="end"
          style={{
            padding: "0px 24px 24px",
          }}
        >
          <Button
            type="primary"
            loading={loading}
            disabled={loading}
            onClick={handleUpdateTool}
            className="createPlanBtn"
          >
            {"Save"}
          </Button>
        </Row>
      </Modal>

      <div className={styles.projectPlanDetails}>
        <div className={styles.projectPlanDetails_form}>
          <div className={styles.projectPlanDetails_header}>
            <h2>Resources</h2>
          </div>

          <div className={styles.form}>
            <div className={styles.form_left}>
              <div className={styles.collaborators}>
                <div className={styles.collaborators_heading}>
                  <h3>Collaborators</h3>

                  <div className={styles.actions}>
                    <Popover
                      content={
                        <div
                          style={{
                            width: "380px",
                          }}
                        >
                          <div className="space-between mb-1 text_bold font-16">
                            <div>Collaborators</div>
                            <CloseOutlined
                              onClick={() => handleChange("peoplePop", false)}
                            />
                          </div>
                          <div className="peopleHeight">
                            {selectedUsers.length ? (
                              selectedUsers?.map((people, index) => (
                                <Row
                                  className="mt-1"
                                  align="middle"
                                  justify="space-between"
                                  key={people?.id}
                                >
                                  <Row align="middle">
                                    <div key={index} className="greyBox">
                                      {people?.email?.substring(0, 2)}
                                    </div>
                                    <div>{people?.email}</div>
                                  </Row>
                                  <Button
                                    onClick={() =>
                                      handleAddSelectedUsers(people)
                                    }
                                    color={COLORS.primary}
                                    className={"b-1 addedButton"}
                                  >
                                    ADDED <CloseOutlined />
                                  </Button>
                                </Row>
                              ))
                            ) : (
                              <div className="empty-div">
                                No collaborators added yet
                              </div>
                            )}
                          </div>
                          <Divider
                            style={{
                              marginTop: 8,
                              marginBottom: 8,
                            }}
                          />
                          <AppInput
                            label={"Add collaborator"}
                            value={searchUser}
                            name={"searchUser"}
                            onChange={filtered}
                            placeholder="Choose a collaborator"
                            height={36}
                          />
                          <div className="peopleHeight mt-1">
                            {removeAlready()?.length === 0 &&
                              searchUser !== "" && (
                                <Row
                                  className="mt-1 mb-1"
                                  align="middle"
                                  justify="space-between"
                                >
                                  <Button
                                    onClick={handleInvite}
                                    loading={inviteLoading}
                                    color={COLORS.primary}
                                    type="primary"
                                    disabled={
                                      searchUser === user?.data?.email ||
                                      !validateEmail(searchUser)
                                    }
                                    style={{ color: COLORS.white }}
                                    className={"b-1 addButton"}
                                  >
                                    <PlusOutlined style={{ marginTop: 2 }} />{" "}
                                    {searchUser === user?.data?.email
                                      ? "You cannot invite yourself"
                                      : validateEmail(searchUser)
                                      ? "Invite " + searchUser
                                      : "No results. Enter email to invite"}
                                  </Button>
                                </Row>
                              )}

                            {removeAlready()?.map((owner) => (
                              <Row
                                className="mt-1 mb-1"
                                align="middle"
                                justify="space-between"
                                key={owner?.id}
                              >
                                <Row align="middle">
                                  <div className="greyBox">
                                    {owner?.email?.substring(0, 2)}
                                  </div>
                                  <div>{owner?.email}</div>
                                </Row>
                                <Button
                                  onClick={() => handleAddSelectedUsers(owner)}
                                  color={COLORS.primary}
                                  type="primary"
                                  className={"b-1 addButton"}
                                >
                                  <PlusOutlined style={{ marginTop: 2 }} />
                                  ADD
                                </Button>
                              </Row>
                            ))}
                          </div>
                          <Divider className="width110 mb-2 mt-2" />
                          <Row justify="end">
                            <Button
                              type="primary"
                              loading={loading}
                              disabled={disabledPeopleButton || !hasChanges}
                              onClick={handleAddPeople}
                              className="createPlanBtn"
                              style={{
                                height: 36,
                              }}
                            >
                              {"Save"}
                            </Button>
                          </Row>
                        </div>
                      }
                      trigger="click"
                      getPopupContainer={(trigger) => trigger.parentElement}
                      placement="bottomRight"
                      open={peoplePop}
                      onOpenChange={(value) => {
                        handleChange("peoplePop", value);
                        setHasChanges(false);
                      }}
                    >
                      <button
                        className={styles.actionButton}
                        onClick={() => {
                          if (selectedUsers.length > 0) {
                            handleChangeLocal("disabledPeopleButton", false);
                          }
                          handleChange("peoplePop", true);
                        }}
                      >
                        <PlusBlue />
                        Add
                      </button>
                    </Popover>

                    <Popover
                      content={
                        <div
                          style={{
                            width: "380px",
                          }}
                        >
                          <div className="space-between mb-3 text_500 font-14 text_blue_dark">
                            <div>Collaborator Requests</div>
                            <CloseOutlined
                              onClick={() => handleChange("requestPop", false)}
                            />
                          </div>

                          <Collapse
                            bordered={true}
                            showArrow={"none"}
                            expandIconPosition="left"
                            ghost
                            className="requestCollapse"
                            destroyInactivePanel={true}
                          >
                            {collaboratorRequests?.length > 0 ? (
                              collaboratorRequests?.map((request, index) => {
                                const avatarIcon =
                                  request?.user_detail?.first_name &&
                                  request?.user_detail?.last_name
                                    ? request?.user_detail?.first_name?.substring(
                                        0,
                                        1
                                      ) +
                                      request?.user_detail?.last_name?.substring(
                                        0,
                                        1
                                      )
                                    : request?.user_detail?.email?.substring(
                                        0,
                                        2
                                      );

                                const userName =
                                  request?.user_detail?.first_name ||
                                  request?.user_detail?.last_name
                                    ? request?.user_detail?.first_name +
                                      " " +
                                      request?.user_detail?.last_name
                                    : request?.user_detail?.name ||
                                      request?.user_detail?.email;

                                return (
                                  <Panel
                                    header={
                                      <Row
                                        align="middle"
                                        className="request-header"
                                        index={index}
                                      >
                                        <div className="request-header-content">
                                          <div className="request-header-avatar">
                                            {avatarIcon}
                                          </div>
                                          <h3>{userName}</h3>
                                        </div>
                                        <div className="request-actions">
                                          <button
                                            onClick={(e) =>
                                              handleDeny(e, request)
                                            }
                                          >
                                            Deny
                                          </button>
                                          <button
                                            onClick={(e) =>
                                              handleApprove(e, request)
                                            }
                                          >
                                            Approve
                                          </button>
                                        </div>
                                      </Row>
                                    }
                                    key={index}
                                  >
                                    <div
                                      className="request-header-detail"
                                      key={index}
                                    >
                                      <div>
                                        <h3>Email</h3>
                                        <p>{request?.user_detail?.email}</p>
                                      </div>
                                      <div>
                                        <h3>Message</h3>
                                        <p>{request?.message}</p>
                                      </div>
                                    </div>
                                  </Panel>
                                );
                              })
                            ) : (
                              <div className="noRequestAvailable">
                                No collaborator Requests Available
                              </div>
                            )}
                          </Collapse>
                        </div>
                      }
                      trigger="click"
                      getPopupContainer={(trigger) => trigger.parentElement}
                      placement="bottomRight"
                      open={requestPop}
                      onOpenChange={(value) => {
                        handleChange("requestPop", value);
                      }}
                    >
                      <Button
                        type="text"
                        className="text_primary b-1 ml-1 py-0 bg_light_blue font-12"
                        style={{ height: 22 }}
                      >
                        Requests{" "}
                        {collaboratorRequests?.length > 0
                          ? `(${collaboratorRequests?.length})`
                          : "(0)"}
                      </Button>
                    </Popover>
                  </div>
                </div>

                <div className={styles.collaborators_list}>
                  {planDetails?.resource?.people?.length ? (
                    planDetails?.resource?.people?.map((people, index) => (
                      <div className={styles.collaborators_avatar} key={index}>
                        {people?.first_name && people?.last_name
                          ? `${people.first_name[0]}${people.last_name[0]}`
                          : people?.email?.substring(0, 2)}
                      </div>
                    ))
                  ) : (
                    <div className={styles.nodataAdd}>
                      No collaborators invited
                    </div>
                  )}
                </div>
              </div>

              <div className={styles.techStack}>
                <div className={styles.techStack_heading}>
                  <h3>Tech Stack</h3>

                  <div className={styles.actions}>
                    <Popover
                      content={
                        <div style={{ width: "380px", maxWidth: 480 }}>
                          <div className="space-between text_500 font-14 text_blue_dark mb-2">
                            <div>Add Tool</div>
                            <CloseOutlined
                              onClick={() => handleChange("toolPop", false)}
                            />
                          </div>
                          <div className="peopleHeight mb-2">
                            <div className="tagsRow">
                              {selectedTools
                                ?.filter((tool) => tool && tool.id)
                                ?.map((tool, index) => {
                                  return (
                                    <Tag
                                      key={index}
                                      closable
                                      onClose={(e) => {
                                        e.preventDefault();
                                        handleAddSelectedTools(tool);
                                      }}
                                      color={COLORS.toolBG}
                                      className={"text_tag mr-0"}
                                    >
                                      {tool?.name}
                                    </Tag>
                                  );
                                })}
                            </div>
                          </div>
                          <div className="font-14 mb-1">Tool Name</div>
                          <AppInput
                            value={searchTool}
                            name="searchTool"
                            onChange={(key, value) =>
                              filtered(key, value, true)
                            }
                            placeholder="e. g. Laptop, Windows"
                          />
                          <div
                            className="peopleHeight"
                            style={{
                              marginTop: 10,
                            }}
                          >
                            {removeToolsAlready()?.length === 0 &&
                              searchTool !== "" && (
                                <Row
                                  className="mt-1 mb-1"
                                  align="middle"
                                  justify="space-between"
                                >
                                  <Button
                                    onClick={handleAddNewTool}
                                    loading={invitePlan}
                                    color={COLORS.primary}
                                    type="primary"
                                    disabled={checkSameTool(searchTool)}
                                    style={{ color: COLORS.white }}
                                    className={"b-1 addButton"}
                                  >
                                    <PlusOutlined style={{ marginTop: 2 }} />
                                    {checkSameTool(searchTool)
                                      ? "You already have this tool"
                                      : 'Add New Tool "' + searchTool + '"'}
                                  </Button>
                                </Row>
                              )}
                            {removeToolsAlready()?.map((tool, index) => (
                              <Row
                                key={index}
                                align="middle"
                                justify="space-between"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  gap: 4,
                                  margin: "8px 0px ",
                                }}
                              >
                                <Row
                                  align="middle"
                                  className="text-oneline"
                                  style={{
                                    maxWidth: 300,
                                  }}
                                >
                                  <div>{tool?.name}</div>
                                </Row>
                                <Button
                                  onClick={() => handleAddSelectedTools(tool)}
                                  color={COLORS.primary}
                                  type="primary"
                                  className={"b-1 addButton"}
                                >
                                  <PlusOutlined style={{ marginTop: 2 }} /> ADD
                                </Button>
                              </Row>
                            ))}
                          </div>
                          <Divider
                            className="width110"
                            style={{
                              margin: "10px 0px",
                            }}
                          />
                          <Row justify="end">
                            <Button
                              type="primary"
                              loading={loading}
                              disabled={disabledToolButton}
                              onClick={handleAddTool}
                              className="createPlanBtn"
                            >
                              {"Save"}
                            </Button>
                          </Row>
                        </div>
                      }
                      trigger="click"
                      overlayClassName="toolPop"
                      placement="bottomLeft"
                      open={toolPop}
                      onOpenChange={(value) => handleChange("toolPop", value)}
                      getPopupContainer={(trigger) => trigger.parentElement}
                      visible={toolPop}
                    >
                      <Row align={"middle"} className={styles.actions}>
                        <button
                          onClick={() => handleChange("toolPop", true)}
                          className={styles.actionButton}
                        >
                          <PlusBlue />
                          Add
                        </button>

                        {editTool?.length > 0 && (
                          <EditGray
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsEditTools(true);
                              handleChange("toolPop", false);
                            }}
                          />
                        )}
                      </Row>
                    </Popover>
                  </div>
                </div>

                <div className={styles.techStack_lists}>
                  {planDetails?.resource?.tools?.length > 0 ? (
                    planDetails?.resource?.tools?.map((tool, index) => (
                      <div className={styles.techStack_list} key={index}>
                        {tool?.name}
                      </div>
                    ))
                  ) : (
                    <div className={styles.nodataAdd}>No tools added</div>
                  )}
                </div>
              </div>

              <div className={styles.techStack}>
                <div className={styles.techStack_heading}>
                  <h3>Public Link</h3>
                </div>

                <PublicLink project={planDetails} />
              </div>
            </div>

            <div className={styles.form_right}>
              <div className={styles.documents}>
                <div className={styles.documents_heading}>
                  <h3>Documents</h3>

                  <div className={styles.actions}>
                    <button
                      onClick={handleButtonClick}
                      className={styles.actionButton}
                    >
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        accept="image/*"
                      />
                      <PlusBlue />
                      Add
                    </button>

                    {/* <EditGray /> */}
                  </div>
                </div>

                <div className={styles.documents_lists}>
                  {fileList?.map((file, index) => {
                    const ext = get_url_title(file?.url);
                    return (
                      <div className={styles.documents_list}>
                        <div className={styles.documents_list_left}>
                          <div className={styles.documents_icon}>
                            <File />
                          </div>
                          <div className={styles.documents_content}>
                            <h3 className="text-oneline">{ext}</h3>
                            {/* <span>525KB</span> */}
                          </div>
                        </div>

                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <Dropdown
                            menu={{
                              items: items(file),
                            }}
                            getPopupContainer={(trigger) =>
                              trigger.parentElement
                            }
                            trigger={["click"]}
                          >
                            <MenuDots className="cursor-pointer" />
                          </Dropdown>
                        </div>
                      </div>
                    );
                  })}
                  {/* <div className={styles.documents_list}>
                  <div className={styles.documents_list_left}>
                    <div className={styles.documents_icon}>
                      <File />
                    </div>
                    <div className={styles.documents_content}>
                      <h3>Mission Statement.pdf</h3>
                      <span>525KB</span>
                    </div>
                  </div>
                  <MenuDots className="cursor-pointer" />
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.projectPlanDetails_notes}></div>
      </div>
    </>
  );
}
